const {
  LOAD,
  REGISTER_MEMBER,
  LOGIN_MEMBER,
  FAILD,
  CURRENT_USER,
  SUCCESS,
  LOGOUT_MEMBER,
  GET_ONE_USER,
  CLEAR_ERRORS,
  BYE_BYE,
} = require("../constants/member");

// initial state
const initialState = {
  member: {},
  Updatemember: {},

  members: [{}],
  error: null,
  isLoad: false,
  isAuth: false,
};

const memberReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD:
      return { ...state, isLoad: true, isAuth: false };
    case REGISTER_MEMBER:
      return { ...state, member: payload.member };
    case LOGIN_MEMBER:
      localStorage.setItem("token", payload.token);
      return { ...state, isAuth: true, isLoad: false };
    case SUCCESS:
      return { ...state, isLoad: false, members: payload.members };
    case FAILD:
      return { ...state, errors: payload.errors, isLoad: false, isAuth: false };
    case CURRENT_USER:
      return {
        ...state,
        member: payload.user,
        isAuth: true,
        isLoad: false,
        error: false,
      };
    case LOGOUT_MEMBER:
      localStorage.removeItem("token");
      return {
        member: null,
        members: [{}],
        error: null,
        isLoad: false,
        isAuth: false,
      };

    case GET_ONE_USER:
      return { ...state, Updatemember: payload.result, isLoad: false };

    case CLEAR_ERRORS:
      return { ...state, errors: null };

    default:
      return state;
    /* eslint-disable */
  }
};

export default memberReducers;
