import React from "react";
import { FaCalendarPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { deleteSecourisme } from "../JS/actions/secourisme";
import "./Secourisme.css";
const Secourisme = ({ secourism }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    const result = window.confirm("are you sure");
    if (result) {
      dispatch(deleteSecourisme(secourism._id));
    }
  };
  return (
    <div class="main-container">
      <div class="heading"></div>
      <div class="cardss">
        <div class="cardd card-6">
          <div class="card__icon">
            <b> {secourism.title} </b>
            <FaCalendarPlus style={{ fontSize: "29px", color: "white" }} />{" "}
          </div>
          <p class="card__exit">
            {/* <i class="fas fa-times"></i> */} Quantity : {secourism.qte}{" "}
            {/* {secourism.description} */}
          </p>
          <p class="card__title">{secourism.description}</p>
          <p class="card__apply">
            <a onClick={handleDelete} class="card__link">
              Delete Now{" "}
              <MdDelete style={{ color: "white", fontSize: "22px" }} />{" "}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Secourisme;
