import React from "react";
import {
  FaBookOpen,
  FaCalendarPlus,
  FaEllipsisH,
  FaExclamation,
  FaHandsHelping,
  FaHeart,
  FaSmileBeam,
} from "react-icons/fa";
import {
  MdFastfood,
  MdFoodBank,
  MdNoFood,
  MdOutlineAddCircle,
  MdOutlineFastfood,
  MdOutlineFoodBank,
} from "react-icons/md";
import { Link } from "react-router-dom";
import "./Logistique.css";

const Logistique = () => {
  return (
    <div style={{ display: "block" }} className="main__cards">
      <div className="card">
        <div className="card_inner">
          <Link to="addNourritures">
            <MdOutlineAddCircle style={{ fontSize: "20px" }} />
          </Link>
          <p className="text-primary-p">
            {" "}
            <Link
              style={{ color: "#a5aaad", textDecoration: "none" }}
              to="Nourritures"
            >
              {" "}
              Nourritures{" "}
            </Link>
          </p>
          {/* <span className="font-bold text-title">0</span> */}
          <MdFastfood style={{ fontSize: "30px", color: "orange" }} />
        </div>
      </div>
      <div className="card">
        {/* <i className="fa fa-calendar fa-2x text-red" aria-hidden="true"></i> */}

        <div className="card_inner">
          <Link to="/addFournitures">
            <MdOutlineAddCircle style={{ fontSize: "20px" }} />
          </Link>
          <p className="text-primary-p">
            {" "}
            <Link
              style={{ color: "#a5aaad", textDecoration: "none" }}
              to="/Fournituress"
            >
              {" "}
              Fourniture scolaire{" "}
            </Link>{" "}
          </p>
          {/* <span className="font-bold text-title">0</span> */}
          <FaBookOpen style={{ fontSize: "30px", color: "#38e1b0" }} />
        </div>
      </div>
      <div className="card">
        {/* <i
                className="fa fa-thumbs-up fa-2x text-green"
                aria-hidden="true"
              ></i> */}
        <div className="card_inner">
          <Link to="AddSecourisme">
            <MdOutlineAddCircle style={{ fontSize: "20px" }} />
          </Link>
          <p className="text-primary-p">
            {" "}
            <Link
              style={{ color: "#a5aaad", textDecoration: "none" }}
              to="Secourismes"
            >
              {" "}
              Secourisme{" "}
            </Link>{" "}
          </p>
          <FaCalendarPlus style={{ fontSize: "29px", color: "red" }} />
        </div>
      </div>
      <div className="card">
        <div className="card_inner">
          <Link to="addOther">
            <MdOutlineAddCircle style={{ fontSize: "20px" }} />
          </Link>
          <p className="text-primary-p">
            {" "}
            <Link
              style={{ color: "#a5aaad", textDecoration: "none" }}
              to="others"
            >
              {" "}
              Others{" "}
            </Link>{" "}
          </p>

          {/* <FaEllipsisH style={{ fontSize: "20px", color: "blue" }} /> */}
          <FaExclamation style={{ fontSize: "20px", color: "greenYellow" }} />
          {/* <FaRocket style={{ fontSize: "25px" }} /> */}
        </div>
      </div>
      <h1 className="crt2">
        Volunteering is an act offered to humanity.{" "}
        <FaHandsHelping style={{ color: "orange", fontSize: "40px" }} />{" "}
        {/* <FaSmileBeam style={{ fontSize: "40px" }} /> */}
        <FaHeart style={{ color: "red", fontSize: "40px" }} />
      </h1>
    </div>
  );
};

export default Logistique;
