import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { addEvents, editEvent } from "../JS/actions/Event";
import "./AddEvent.css";
const AddEvent = () => {
  const [event, setEvent] = useState({});
  const [edit, setEdit] = useState(false);
  const eventToEdit = useSelector((state) => state.eventReducers.event);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      setEdit(true);
    } else {
      setEdit(false);
    }
    edit
      ? setEvent(eventToEdit)
      : setEvent({
          eventName: "",
          eventDescription: "",
          eventType: "",
          eventDate: "",
          eventPlace: "",
        });
  }, [params, edit, eventToEdit]);

  const handleChange = (e) => {
    setEvent({ ...event, [e.target.name]: e.target.value });
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (edit) {
      dispatch(editEvent(params.id, event, history));
      alert("event updated succesfully :)");
    } else {
      dispatch(addEvents(event, history));
      alert("event added succesfully :)");
    }
  };
  return (
    <div>
      <Form className="addFamily">
        <h1 style={{ textAlign: "center", color: "black" }}> Ajouter Event </h1>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Name event</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Entrez le nom"
              name="eventName"
              value={event.eventName}
              required
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Event desription</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Description"
              name="eventDescription"
              value={event.eventDescription}
              required
            />
          </Form.Group>
        </Row>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Type event</Form.Label>
          <Form.Select
            onChange={handleChange}
            placeholder="Type event"
            name="eventType"
            type="text"
            defaultValue="Choose..."
            value={event.eventType}
            required
          >
            <option>Choisir...</option>
            <option>Formation</option>
            <option>Action</option>
            <option>Reunion</option>
          </Form.Select>
        </Form.Group>

        <Row className="mb-3"></Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Date event</Form.Label>
            <Form.Control
              onChange={handleChange}
              placeholder="date"
              name="dateEvent"
              type="date"
              value={event.dateEvent}
              required
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Place event</Form.Label>
            <Form.Control
              onChange={handleChange}
              placeholder="Place"
              name="eventPlace"
              value={event.eventPlace}
              required
            />
          </Form.Group>
        </Row>

        <Button
          onClick={handleSave}
          style={{ marginBottom: "15px" }}
          variant="primary"
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default AddEvent;
