import {
  GET_SECOURISME_FAILD,
  GET_SECOURISME_LOAD,
  GET_SECOURISME_SUCCESS,
} from "../constants/secourisme";

const initialState = {
  secourismes: [],
  isLoad: false,
  isError: false,
};

const secourismeReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SECOURISME_SUCCESS:
      return {
        ...state,
        secourismes: payload.secourismes,
        isLoad: false,
      };
    case GET_SECOURISME_LOAD:
      return { ...state, isLoad: true };
    case GET_SECOURISME_FAILD:
      return {
        ...state,
        isLoad: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default secourismeReducers;
