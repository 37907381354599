import React from "react";
import "./events.css";
import { MdAccessTime, MdDelete, MdPlace } from "react-icons/md";
import { FaPen, FaTasks, FaUserCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { deleteEvent, getallEvent, getoneEvent } from "../JS/actions/Event";
import { Link } from "react-router-dom";

const Events = ({ event }) => {
  const user = useSelector((state) => state.memberReducers.member);

  const dispatch = useDispatch();
  const handleEvent = () => {
    dispatch(getoneEvent(event._id));
  };
  const handleDelete = () => {
    const result = window.confirm("are you sure");
    if (result) {
      dispatch(deleteEvent(event._id));
    }
  };
  return (
    <>
      {event.eventType == "Reunion" ? (
        <div>
          <div class="pt-2">
            <div
              style={{
                background: "linear-gradient(498deg, #e90001, transparent)",
              }}
              class="two"
            >
              <div class="d-flex justify-content-end px-3 pt-1">
                <i class="mdi mdi-star-outline pr-1 star"></i>
                <i class="mdi mdi-dots-horizontal dot"></i>
              </div>
              <div class="px-3">
                <div class="round">
                  <img
                    src="https://img.icons8.com/emoji/48/000000/hedgehog-emoji.png"
                    width="23"
                    class="imgfix"
                    alt="img"
                  />
                </div>
              </div>
              <div class="px-3 pt-3">
                <h3 style={{ color: "white" }} class="name">
                  {event.eventName}
                </h3>
                <p style={{ color: "white" }} class="quote2">
                  {event.eventDescription}
                </p>
              </div>
              <div class="d-flex justify-content-start px-3 align-items-center">
                {" "}
                <FaTasks style={{ color: "dodgerblue" }} />
                <span style={{ color: "white" }} class="quote2 pl-2">
                  {" "}
                  Type: {event.eventType}
                </span>{" "}
              </div>
              <div class="d-flex justify-content-start px-3 align-items-center">
                <MdAccessTime
                  style={{ color: "dodgerblue", fontSize: "26px" }}
                />
                <span style={{ color: "white" }} class="quote2 pl-2">
                  {" "}
                  Date : {event.dateEvent}
                </span>{" "}
              </div>
              <div class="d-flex justify-content-between px-3 align-items-center pb-3">
                <div class="d-flex justify-content-start align-items-center">
                  {" "}
                  <MdPlace style={{ color: "darkorange" }} />
                  <span style={{ color: "white" }} class="quote2 pl-2">
                    {event.eventPlace}{" "}
                  </span>{" "}
                </div>
                <div class="d-flex justify-content-end">
                  <Link to={`/edit/${event._id}`}>
                    <FaPen
                      onClick={handleEvent}
                      style={{ color: "green", fontSize: "18px" }}
                    />
                  </Link>
                  <MdDelete
                    onClick={handleDelete}
                    style={{ color: "red", fontSize: "22px" }}
                  />{" "}
                </div>
              </div>
              <b style={{ color: "white" }}>
                {" "}
                <FaUserCheck
                  style={{
                    color: "white",
                    fontSize: "20px",
                    marginRight: "5px",
                    marginBottom: "4px",
                  }}
                />{" "}
                : {event.nameMember}{" "}
              </b>
            </div>
          </div>
        </div>
      ) : event.eventType == "Formation" ? (
        <div>
          <div class="pt-2">
            <div
              style={{
                background:
                  "linear-gradient(498deg, rgb(30 187 2), transparent)",
              }}
              class="two"
            >
              <div class="d-flex justify-content-end px-3 pt-1">
                <i class="mdi mdi-star-outline pr-1 star"></i>
                <i class="mdi mdi-dots-horizontal dot"></i>
              </div>
              <div class="px-3">
                <div class="round">
                  <img
                    src="https://img.icons8.com/emoji/48/000000/hedgehog-emoji.png"
                    width="23"
                    class="imgfix"
                    alt="img"
                  />
                </div>
              </div>
              <div class="px-3 pt-3">
                <h3 style={{ color: "white" }} class="name">
                  {event.eventName}
                </h3>
                <p style={{ color: "white" }} class="quote2">
                  {event.eventDescription}
                </p>
              </div>
              <div class="d-flex justify-content-start px-3 align-items-center">
                {" "}
                <FaTasks style={{ color: "dodgerblue" }} />
                <span style={{ color: "white" }} class="quote2 pl-2">
                  {" "}
                  Type: {event.eventType}
                </span>{" "}
              </div>
              <div class="d-flex justify-content-start px-3 align-items-center">
                <MdAccessTime
                  style={{ color: "dodgerblue", fontSize: "26px" }}
                />
                <span style={{ color: "white" }} class="quote2 pl-2">
                  {" "}
                  Date : {event.dateEvent}
                </span>{" "}
              </div>
              <div class="d-flex justify-content-between px-3 align-items-center pb-3">
                <div class="d-flex justify-content-start align-items-center">
                  {" "}
                  <MdPlace style={{ color: "darkorange" }} />
                  <span style={{ color: "white" }} class="quote2 pl-2">
                    {event.eventPlace}{" "}
                  </span>{" "}
                </div>
                {user.role == "admin" ? (
                  <div class="d-flex justify-content-end">
                    <Link to={`/edit/${event._id}`}>
                      <FaPen
                        onClick={handleEvent}
                        style={{ color: "green", fontSize: "18px" }}
                      />
                    </Link>
                    <Link>
                      <MdDelete
                        onClick={handleDelete}
                        style={{ color: "red", fontSize: "22px" }}
                      />{" "}
                    </Link>{" "}
                  </div>
                ) : null}
              </div>
              <b style={{ color: "white" }}>
                {" "}
                <FaUserCheck
                  style={{
                    color: "white",
                    fontSize: "20px",
                    marginRight: "5px",
                    marginBottom: "4px",
                  }}
                />{" "}
                : {event.nameMember}{" "}
              </b>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div class="pt-2">
            <div class="two">
              <div class="d-flex justify-content-end px-3 pt-1">
                <i class="mdi mdi-star-outline pr-1 star"></i>
                <i class="mdi mdi-dots-horizontal dot"></i>
              </div>
              <div class="px-3">
                <div class="round">
                  <img
                    src="https://img.icons8.com/emoji/48/000000/hedgehog-emoji.png"
                    width="23"
                    class="imgfix"
                    alt="img"
                  />
                </div>
              </div>
              <div class="px-3 pt-3">
                <h3 class="name">{event.eventName}</h3>
                <p class="quote2">{event.eventDescription}</p>
              </div>
              <div class="d-flex justify-content-start px-3 align-items-center">
                {" "}
                <FaTasks style={{ color: "dodgerblue" }} />
                <span class="quote2 pl-2"> Type: {event.eventType}</span>{" "}
              </div>
              <div class="d-flex justify-content-start px-3 align-items-center">
                <MdAccessTime
                  style={{ color: "dodgerblue", fontSize: "26px" }}
                />
                <span class="quote2 pl-2"> Date : {event.dateEvent}</span>{" "}
              </div>
              <div class="d-flex justify-content-between px-3 align-items-center pb-3">
                <div class="d-flex justify-content-start align-items-center">
                  {" "}
                  <MdPlace style={{ color: "darkorange" }} />
                  <span class="quote2 pl-2">{event.eventPlace} </span>{" "}
                </div>
                <div class="d-flex justify-content-end">
                  <Link to={`/edit/${event._id}`}>
                    <FaPen
                      onClick={handleEvent}
                      style={{ color: "green", fontSize: "18px" }}
                    />
                  </Link>
                  <Link>
                    <MdDelete
                      onClick={handleDelete}
                      style={{ color: "red", fontSize: "22px" }}
                    />{" "}
                  </Link>{" "}
                </div>
              </div>
              <b>
                {" "}
                <FaUserCheck
                  style={{
                    color: "darkOrange",
                    fontSize: "20px",
                    marginRight: "5px",
                    marginBottom: "4px",
                  }}
                />{" "}
                : {event.nameMember}{" "}
              </b>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Events;
