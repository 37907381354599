import {
  GET_FACTURE_SUCCESS,
  GET_FACTURE_LOAD,
  GET_FACTURE_FAILED,
} from "../constants/Facture";

const initialState = {
  factures: [],
  isLoad: false,
  isError: false,
};

const factureReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FACTURE_SUCCESS:
      return {
        ...state,
        factures: payload.factures,
        isLoad: false,
      };
    case GET_FACTURE_LOAD:
      return { ...state, isLoad: true };
    case GET_FACTURE_FAILED:
      return {
        ...state,
        isLoad: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default factureReducers;
