import React from "react";
import { FaDove, FaHandsHelping, FaHeart, FaSmileBeam } from "react-icons/fa";
import "./Vibes.css";

const Vibes = () => {
  return (
    <>
      <h1 style={{ textAlign: "center", marginBottom: "10px" }}>
        {" "}
        Gallery - Positive Vibes{" "}
        <FaSmileBeam style={{ fontSize: "40px", color: "yellow" }} />
      </h1>
      <div style={{ border: "outset" }}>
        <h1 className="crtt">
          Be Positive...{" "}
          <FaSmileBeam style={{ fontSize: "40px", color: "yellow" }} />
        </h1>
        <h1 className="crt2">
          Volunteering is an act offered to humanity.{" "}
          <FaHandsHelping style={{ color: "orange", fontSize: "40px" }} />{" "}
          {/* <FaSmileBeam style={{ fontSize: "40px" }} /> */}
          <FaHeart style={{ color: "red", fontSize: "40px" }} />
        </h1>
        <h1 className="crtt3">
          Have a good day...{" "}
          <FaDove style={{ fontSize: "40px", color: "lightBlue" }} />
        </h1>
      </div>
    </>
  );
};

export default Vibes;
