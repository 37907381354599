import axios from "axios";
import {
  GET_SECOURISME_FAILD,
  GET_SECOURISME_LOAD,
  GET_SECOURISME_SUCCESS,
} from "../constants/secourisme";

export const getallSecourisme = () => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: GET_SECOURISME_LOAD });

  try {
    let results = await axios.get("/api/secourisme/secourismes", config);
    // if i send an http request result bech tekho objet { status , headers ,data li jet mel api }
    dispatch({
      type: GET_SECOURISME_SUCCESS,
      payload: results.data, // (hedhy tji feha un objet fih msg w EVENT)
    });
  } catch (error) {
    dispatch({ type: GET_SECOURISME_FAILD });
    console.log(error);
  }
};
export const addSecourisme = (secourisme, history) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: GET_SECOURISME_LOAD });
  try {
    await axios.post("/api/secourisme", secourisme, config);
    // dispatch({ type: SUCCESS });
    dispatch(getallSecourisme());

    history.push("/Secourismes");
  } catch (error) {
    dispatch({ type: GET_SECOURISME_FAILD });
    console.log(error);
  }
};

export const deleteSecourisme = (id) => async (dispatch) => {
  dispatch({ type: GET_SECOURISME_LOAD });
  try {
    await axios.delete(`/api/secourisme/${id}`);
    dispatch(getallSecourisme());
  } catch (error) {
    dispatch({ type: GET_SECOURISME_FAILD });
    console.log(error);
  }
};
