import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { addFamily, editFamily } from "../JS/actions/Family";
import "./AddFamily.css";
const AddFamily = () => {
  const [family, setFamily] = useState({});
  const [edit, setEdit] = useState(false);
  const familyToEdit = useSelector((state) => state.familyReducers.family);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      setEdit(true);
    } else {
      setEdit(false);
    }
    edit
      ? setFamily(familyToEdit)
      : setFamily({
          firstName: "",
          lastName: "",
          adresse: "",
          descEnfant: "",
          numCin: "",
          etatMaison: "",
          etatSocial: "",
          phone: "",
          nbEnfant: "",
          job: "",
        });
  }, [params, edit, familyToEdit]);

  const handleChange = (e) => {
    setFamily({ ...family, [e.target.name]: e.target.value });
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (edit) {
      dispatch(editFamily(params.id, family, history));
      alert("family updated succesfully :)");
    } else dispatch(addFamily(family, history));
    alert("Family added succesfully :)");
  };

  return (
    <Form className="addFamily">
      <h1 style={{ textAlign: "center", color: "black" }}>
        {" "}
        Ajouter une famille{" "}
      </h1>

      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label>Nom</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="text"
            placeholder="Entrez le nom"
            name="firstName"
            required
            value={family.firstName}
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Prenom</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="text"
            placeholder="Entrez le Prenom"
            name="lastName"
            required
            value={family.lastName}
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Address</Form.Label>
        <Form.Control
          onChange={handleChange}
          placeholder="Entrez l'adresse"
          name="adresse"
          type="text"
          required
          value={family.adresse}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label>Desc enfants</Form.Label>
        <Form.Control
          onChange={handleChange}
          placeholder="Desc enfants"
          name="descEnfant"
          type="text"
          required
          value={family.descEnfant}
        />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>CIN</Form.Label>
          <Form.Control
            onChange={handleChange}
            placeholder="CIN"
            name="numCin"
            type="number"
            required
            value={family.numCin}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Etat maison</Form.Label>
          <Form.Select
            onChange={handleChange}
            placeholder="Etat maison"
            name="etatMaison"
            type="text"
            defaultValue="Choose..."
            required
            value={family.etatMaison}
          >
            <option>Choisir...</option>
            <option>grave</option>
            <option>très grave</option>
            <option>passable</option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Etat Social</Form.Label>
          <Form.Select
            onChange={handleChange}
            placeholder="etatSocial"
            name="etatSocial"
            type="text"
            defaultValue="Choose..."
            required
            value={family.etatSocial}
          >
            <option>Choisir...</option>
            <option>grave</option>
            <option>très grave</option>
            <option>passable</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            onChange={handleChange}
            placeholder="Phone"
            name="phone"
            type="number"
            required
            value={family.phone}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Nbr enfants</Form.Label>
          <Form.Control
            onChange={handleChange}
            placeholder="Nbr enfants"
            name="nbEnfant"
            type="number"
            required
            value={family.nbEnfant}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Job</Form.Label>
          <Form.Control
            onChange={handleChange}
            placeholder="Travail"
            name="job"
            required
            value={family.job}
          />
        </Form.Group>
      </Row>

      <Button
        onClick={handleSave}
        style={{ marginBottom: "15px" }}
        variant="primary"
        type="submit"
      >
        Submit
      </Button>
    </Form>
  );
};

export default AddFamily;
