import axios from "axios";
import {
  GET_EVENT_FAILD,
  GET_EVENT_LOAD,
  GET_EVENT_SUCCESS,
  GET_ONE_EVENT,
} from "../constants/Event";

export const getallEvent = () => async (dispatch) => {
  dispatch({ type: GET_EVENT_LOAD });

  try {
    let results = await axios.get("/api/event/events");
    // if i send an http request result bech tekho objet { status , headers ,data li jet mel api }
    dispatch({
      type: GET_EVENT_SUCCESS,
      payload: results.data, // (hedhy tji feha un objet fih msg w EVENT)
    });
  } catch (error) {
    dispatch({ type: GET_EVENT_FAILD });
    console.log(error);
  }
};
export const addEvents = (event, history) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: GET_EVENT_LOAD });
  try {
    await axios.post("/api/event", event, config);
    // dispatch({ type: GET_EVENT_SUCCESS });
    dispatch(getallEvent());

    history.push("/eventss");
  } catch (error) {
    dispatch({ type: GET_EVENT_FAILD });
    console.log(error);
  }
};
export const deleteEvent = (id) => async (dispatch) => {
  dispatch({ type: GET_EVENT_LOAD });
  try {
    await axios.delete(`/api/event/${id}`);
    dispatch(getallEvent());
  } catch (error) {
    dispatch({ type: GET_EVENT_FAILD });
    console.log(error);
  }
};

export const getoneEvent = (id) => async (dispatch) => {
  dispatch({ type: GET_EVENT_LOAD });
  try {
    let result = await axios.get(`/api/event/oneEvent/${id}`);
    dispatch({ type: GET_ONE_EVENT, payload: result.data });
  } catch (error) {
    dispatch({ type: GET_EVENT_FAILD });
  }
};

export const editEvent = (id, event, history) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  try {
    await axios.put(`/api/event/${id}`, event, config);
    dispatch(getallEvent());
    alert("update successfully");
    history.push("/events");
  } catch (error) {
    dispatch({ type: GET_EVENT_FAILD });
    console.log(error);
  }
};
