import axios from "axios";
import {
  GET_FOURNITURE_FAILD,
  GET_FOURNITURE_LOAD,
  GET_FOURNITURE_SUCCESS,
} from "../constants/fournitures";

export const getallFourniture = () => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: GET_FOURNITURE_LOAD });

  try {
    let results = await axios.get("/api/fourniture/fournitures", config);
    // if i send an http request result bech tekho objet { status , headers ,data li jet mel api }
    dispatch({
      type: GET_FOURNITURE_SUCCESS,
      payload: results.data, // (hedhy tji feha un objet fih msg w EVENT)
    });
  } catch (error) {
    dispatch({ type: GET_FOURNITURE_FAILD });
    console.log(error);
  }
};
export const addFourniture = (fourniture, history) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: GET_FOURNITURE_LOAD });
  try {
    await axios.post("/api/fourniture", fourniture, config);
    // dispatch({ type: SUCCESS });
    dispatch(getallFourniture());

    history.push("/Fournituress");
  } catch (error) {
    dispatch({ type: GET_FOURNITURE_FAILD });
    console.log(error);
  }
};
export const deleteFourniture = (id) => async (dispatch) => {
  dispatch({ type: GET_FOURNITURE_LOAD });
  try {
    await axios.delete(`/api/fourniture/${id}`);
    dispatch(getallFourniture());
  } catch (error) {
    dispatch({ type: GET_FOURNITURE_FAILD });
    console.log(error);
  }
};
