import React from "react";
import { MdDelete } from "react-icons/md";
import "./Facture.css";
import { deleteFacture } from "../JS/actions/Facture";
import { useDispatch } from "react-redux";

const Facture = ({ facture }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    const result = window.confirm("are you sure");
    if (result) {
      dispatch(deleteFacture(facture._id));
    }
  };
  return (
    <div>
      <table className="fact">
        {/* <tr>
          <th>Company</th>
          <th>Contact</th>
          <th>Country</th>
        </tr> */}
        <tr>
          <td>{facture.factName}</td>
          <td>{facture.factDescription}</td>
          <td>{facture.factDate}</td>
          <td>{facture.montant} Dt </td>

          <td>
            {" "}
            <MdDelete
              onClick={handleDelete}
              style={{ color: "red", fontSize: "22px" }}
            />{" "}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Facture;
