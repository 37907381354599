import {
  GET_SOLDE_FAILED,
  GET_SOLDE_LOAD,
  GET_SOLDE_SUCCESS,
} from "../constants/Solde";

const initialState = {
  soldes: [],
  isLoad: false,
  isError: false,
};

const soldeReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SOLDE_SUCCESS:
      return {
        ...state,
        soldes: payload.soldes,
        isLoad: false,
      };
    case GET_SOLDE_LOAD:
      return { ...state, isLoad: true };
    case GET_SOLDE_FAILED:
      return {
        ...state,
        isLoad: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default soldeReducers;
