import React, { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { addNourritures } from "../JS/actions/nourritures";
const AddNourr = () => {
  const [nourriture, setNourriture] = useState({});
  const [edit, setEdit] = useState(false);
  const eventToEdit = useSelector((state) => state.eventReducers.event);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  // useEffect(() => {
  //   if (params.id) {
  //     setEdit(true);
  //   } else {
  //     setEdit(false);
  //   }
  //   edit
  //     ? setEvent(eventToEdit)
  //     : setEvent({
  //         eventName: "",
  //         eventDescription: "",
  //         eventType: "",
  //         eventDate: "",
  //         eventPlace: "",
  //       });
  // }, [params, edit, eventToEdit]);
  const handleChange = (e) => {
    setNourriture({ ...nourriture, [e.target.name]: e.target.value });
  };
  const handleSave = (e) => {
    e.preventDefault();
    // if (edit) {
    //   dispatch(editEvent(params.id, event, history));
    //   alert("event updated succesfully :)");
    // } else {
    dispatch(addNourritures(nourriture, history));
    alert("nourr added succesfully :)");
  };
  return (
    <div>
      <Form className="addFamily">
        <h1 style={{ textAlign: "center", color: "black" }}>
          {" "}
          Ajouter Nourritures{" "}
        </h1>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Nom nourriture</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Entrez le nom"
              name="title"
              required
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Nourriture desription</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Description"
              name="description"
              required
            />
          </Form.Group>
        </Row>
        <Form.Group as={Col}>
          <Form.Label>Qte</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="number"
            placeholder="qte"
            name="qte"
            required
          />
        </Form.Group>
        {/* <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Type event</Form.Label>
          <Form.Select
            onChange={handleChange}
            placeholder="Type event"
            name="eventType"
            type="text"
            defaultValue="Choose..."
            required
          >
            <option>Choisir...</option>
            <option>evennement</option>
            <option>action</option>
          </Form.Select>
        </Form.Group> */}

        <Row className="mb-3"></Row>

        <Button
          onClick={handleSave}
          style={{ marginBottom: "15px" }}
          variant="primary"
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default AddNourr;
