import React, { useEffect } from "react";
import { FaCalendarPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getallOther } from "../JS/actions/other";
import Other from "./Other";

const ListSecourisme = () => {
  const others = useSelector((state) => state.otherReducers.others);
  const isLoad = useSelector((state) => state.otherReducers.isLoad);
  const isError = useSelector((state) => state.otherReducers.isError);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallOther());
  }, [dispatch]);
  return (
    <div>
      <>
        {/* <h1> Families list </h1> */}
        {isLoad ? (
          <div className="loading">Loading&#8230</div>
        ) : isError ? (
          <p>Error To get </p>
        ) : (
          <div>
            <h1 style={{ textAlign: "center" }}>
              {" "}
              Stock Others
              {/* <MdFastfood style={{ fontSize: "30px", color: "orange" }} />{" "} */}
            </h1>
            <div className="main">
              {others && others.map((el) => <Other oth={el} key={el._id} />)}{" "}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ListSecourisme;
