import React, { useEffect } from "react";
import { FaPen } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { deleteSolde } from "../JS/actions/Solde";

const Solde = ({ sold }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    const result = window.confirm("are you sure");
    if (result) {
      dispatch(deleteSolde(sold._id));
    }
  };
  return (
    <div>
      <table className="fact">
        {/* <tr>
          <th>Company</th>
          <th>Contact</th>
          <th>Country</th>
        </tr> */}
        <tr>
          <td>{sold.factName}</td>
          <td>{sold.factDescription}</td>
          <td>{sold.factDate}</td>
          <td>{sold.solde} Dt </td>
          {/* <td>{facture.type} </td> */}

          <td>
            {" "}
            <MdDelete
              onClick={handleDelete}
              style={{ color: "red", fontSize: "22px" }}
            />{" "}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Solde;
