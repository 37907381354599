import axios from "axios";
import {
  GET_FAMILY_FAILD,
  GET_FAMILY_LOAD,
  GET_FAMILY_SUCCESS,
  GET_ONE_FAMILY,
} from "../constants/Family";

export const getallFamily = () => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: GET_FAMILY_LOAD });

  try {
    let results = await axios.get("/api/family", config);
    // if i send an http request result bech tekho objet { status , headers ,data li jet mel api }
    dispatch({
      type: GET_FAMILY_SUCCESS,
      payload: results.data, // (hedhy tji feha un objet fih msg w FAMILY)
    });
  } catch (error) {
    dispatch({ type: GET_FAMILY_FAILD });
    console.log(error);
  }
};

export const deleteFamily = (id) => async (dispatch) => {
  dispatch({ type: GET_FAMILY_LOAD });
  try {
    await axios.delete(`/api/family/${id}`);
    dispatch(getallFamily());
  } catch (error) {
    dispatch({ type: GET_FAMILY_FAILD });
    console.log(error);
  }
};
export const getOneFamily = (id) => async (dispatch) => {
  dispatch({ type: GET_FAMILY_LOAD });
  try {
    let result = await axios.get(`/api/family/oneFamily/${id}`);
    dispatch({ type: GET_ONE_FAMILY, payload: result.data });
  } catch (error) {
    dispatch({ type: GET_FAMILY_FAILD });
  }
};

export const editFamily = (id, family, history) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  try {
    await axios.put(`/api/family/${id}`, family, config);
    dispatch(getallFamily());
    history.push("/family");
  } catch (error) {
    dispatch({ type: GET_FAMILY_FAILD });
    console.log(error);
  }
};

export const addFamily = (family, history) => async (dispatch) => {
  dispatch({ type: GET_FAMILY_LOAD });
  try {
    await axios.post("/api/family", family);
    // dispatch({ type: GET_FAMILY_SUCCESS });
    dispatch(getallFamily());

    history.push("/family");
  } catch (error) {
    dispatch({ type: GET_FAMILY_FAILD });
    console.log(error);
  }
};
