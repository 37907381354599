import React from "react";
// import "../../../node_modules/react-vis/dist/style.css";
import "../../node_modules/react-vis/dist/style.css";
import {
  XYPlot,
  LineSeries,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
} from "react-vis";
import "./Chart.css";
import { useSelector } from "react-redux";
import UserStat from "./UserStat";
const Charts = () => {
  const users = useSelector((state) => state.memberReducers.members);

  const data = [
    { x: 0, y: 8 },
    { x: 1, y: 5 },
    { x: 2, y: 4 },
    { x: 3, y: 9 },
    { x: 4, y: 1 },
    { x: 5, y: 7 },
    { x: 6, y: 6 },
    { x: 7, y: 3 },
    { x: 8, y: 2 },
    { x: 9, y: 0 },
  ];
  return (
    <div style={{ marginTop: "15px" }}>
      {/* <XYPlot height={300} width={300}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        <LineSeries data={data} color="red" />
        <LineSeries data={data} color="purple" />
        <LineSeries data={data} color="yellow" />
      </XYPlot> */}
      <div className="tabData">
        <div className=" userCont">
          <div className="table-responsive">
            <table className="table user-list ">
              <tr>
                <th>
                  <span className="theadStyle" style={{ color: "black" }}>
                    User
                  </span>
                </th>
                {/* <th>
                    <span className="theadStyle" style={{ color: "black" }}>
                      Created
                    </span>
                  </th> */}
                <th className="text-center">
                  <span className="theadStyle" style={{ color: "black" }}>
                    Status (Verifed)
                  </span>
                </th>
              </tr>
            </table>
          </div>
        </div>
        {users && users.map((el) => <UserStat user={el} key={el._id} />)}
      </div>
    </div>
  );
};

export default Charts;
