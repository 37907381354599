import "./Navbar.css";
import avatar from "../assets/img/avatar.svg";
import { Link } from "react-router-dom";
import { FaFileInvoiceDollar, FaPlus, FaUserPlus } from "react-icons/fa";
import "./Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { getallSolde } from "../JS/actions/Solde";
import { useEffect } from "react";
import Solde from "./Solde";

const Navbar = ({ sidebarOpen, openSidebar }) => {
  const solde = useSelector((state) => state.soldeReducers.soldes);
  const factures = useSelector((state) => state.factureReducers.factures);
  const user = useSelector((state) => state.memberReducers.member);

  const tot2 = solde.reduce(
    (accumulateur, valeurCourante) => accumulateur + valeurCourante.solde,
    0
  );
  const tot = factures.reduce(
    (accumulateur, valeurCourante) => accumulateur + valeurCourante.montant,
    0
  );

  const result = tot2 - tot;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallSolde());
  }, [dispatch]);
  return (
    <nav className="navbar">
      <div className="nav_icon" onClick={() => openSidebar()}>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </div>
      <div className="navbar__left">
        <FaUserPlus
          style={{ color: "gray", fontSize: "20px", marginRight: "5px" }}
        />

        <a>
          {" "}
          {user.role == "admin" ? (
            <Link to="/AddUser"> Ajouter membre </Link>
          ) : null}
        </a>
        {user.role == "admin" ? (
          <FaUserPlus
            style={{ color: "gray", fontSize: "20px", marginRight: "5px" }}
          />
        ) : null}
        <a>
          {" "}
          <Link to="addFamily"> Ajouter famille </Link>
        </a>
        <FaUserPlus
          style={{ color: "gray", fontSize: "20px", marginRight: "5px" }}
        />
        <a>
          {" "}
          <Link to="/Addevent"> Ajouter event </Link>
        </a>
        {user.role == "admin" ? (
          <a>
            {" "}
            <FaUserPlus
              style={{ color: "gray", fontSize: "20px", marginRight: "5px" }}
            />
            <Link to="/AddFacture"> Solde Sortant </Link>
          </a>
        ) : null}
        {user.role == "admin" ? (
          <a>
            {" "}
            <FaUserPlus
              style={{ color: "gray", fontSize: "20px", marginRight: "5px" }}
            />
            <Link to="/addSolde"> Solde Entrant </Link>
          </a>
        ) : null}
      </div>
      <div className="navbar__right">
        {" "}
        {user.role == "admin" ? (
          <span
            style={{
              color: "red",
              fontWeight: "bold",
              background: "#f3f4f6",
              borderRadius: "10px",
              padding: "5px 11px",
              fontSize: "13px",
            }}
          >
            {" "}
            Total existant : {result}{" "}
          </span>
        ) : null}
        <a href="#">
          {/* <i className="fa fa-search" aria-hidden="true"></i> */}
        </a>
        {/* <a href="#">
          <i className="fa fa-clock-o" aria-hidden="true"></i>
        </a> */}
        {/* <a href="#!">
          <Link to="/profile">
            {" "}
            <img width="30" src={avatar} alt="avatar" />{" "}
          </Link>
        </a> */}
      </div>
    </nav>
  );
};

export default Navbar;
