import axios from "axios";
import {
  GET_FACTURE_SUCCESS,
  GET_FACTURE_LOAD,
  GET_FACTURE_FAILED,
} from "../constants/Facture";

export const getallFacture = () => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: GET_FACTURE_LOAD });

  try {
    let results = await axios.get("/api/facture/factures", config);
    // if i send an http request result bech tekho objet { status , headers ,data li jet mel api }
    dispatch({
      type: GET_FACTURE_SUCCESS,
      payload: results.data, // (hedhy tji feha un objet fih msg w facture)
    });
  } catch (error) {
    dispatch({ type: GET_FACTURE_FAILED });
    console.log(error);
  }
};
export const addFactures = (facture, history) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: GET_FACTURE_LOAD });
  try {
    await axios.post("/api/facture", facture, config);
    // dispatch({ type: SUCCESS });
    dispatch(getallFacture());

    history.push("/Factures");
  } catch (error) {
    dispatch({ type: GET_FACTURE_FAILED });
    console.log(error);
  }
};
export const deleteFacture = (id) => async (dispatch) => {
  dispatch({ type: GET_FACTURE_LOAD });
  try {
    await axios.delete(`/api/facture/${id}`);
    dispatch(getallFacture());
  } catch (error) {
    dispatch({ type: GET_FACTURE_FAILED });
    console.log(error);
  }
};
