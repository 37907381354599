import memberReducers from "./member";
import familyReducers from "./family";
import eventReducers from "./event";
import factureReducers from "./facture";
import nourritureReducers from "./nourriture";
import fournitureReducers from "./fourniture";
import secourismeReducers from "./secourisme";
import otherReducers from "./other";
import soldeReducers from "./solde";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  memberReducers,
  familyReducers,
  eventReducers,
  factureReducers,
  nourritureReducers,
  fournitureReducers,
  secourismeReducers,
  otherReducers,
  soldeReducers,
});

export default rootReducer;
