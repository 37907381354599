import React, { useState } from "react";
import "./register.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCocktail } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../JS/actions/member";
import { useHistory } from "react-router";
import { Notifcation } from "../components/Notifcation";

const Register = () => {
  const errors = useSelector((state) => state.memberReducers.errors);
  const history = useHistory();
  const [member, setmember] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const handleMember = (e) => {
    setmember({ ...member, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();
  const handleRegister = (e) => {
    e.preventDefault();
    dispatch(register(member, history));
    setmember({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    });
  };
  return (
    <div>
      {errors && errors.map((el) => <Notifcation error={el} />)}
      <div className="container" id="container">
        <div className="form-container log-in-container">
          <form action="#">
            <h1>Register</h1>
            <div className="social-container">
              <a href="#" className="social">
                <FontAwesomeIcon
                  style={{ color: "#4267B2", fontSize: "30px" }}
                  icon={faFacebook}
                ></FontAwesomeIcon>
              </a>
              <a href="#" className="social">
                <FontAwesomeIcon
                  style={{ color: "#E1306C", fontSize: "30px" }}
                  icon={faInstagram}
                ></FontAwesomeIcon>
              </a>
            </div>
            <span>Hello friend</span>
            <input
              onChange={handleMember}
              required
              name="firstName"
              type="firstName"
              placeholder="firstName"
              value={member.firstName}
            />
            <input
              onChange={handleMember}
              required
              name="lastName"
              type="lastName"
              placeholder="lastName"
              value={member.lastName}
            />

            <input
              onChange={handleMember}
              required
              name="email"
              type="email"
              placeholder="Email"
              value={member.email}
            />
            <input
              onChange={handleMember}
              required
              name="password"
              type="password"
              placeholder="Password"
              value={member.password}
            />
            <button onClick={handleRegister}>Sing up</button>
            {/* <a href="#">Or create your account</a> */}
          </form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-right">
              <h1>Humanity</h1>
              <p>WE ARE ALWAYS TOGHETER ... HUMANITY FIRST</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
