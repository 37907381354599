import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Solde from "../components/Solde";
import { getallFacture } from "../JS/actions/Facture";
import Facture from "./Facture";
import "./ListFactures.css";

const ListFactures = () => {
  const factures = useSelector((state) => state.factureReducers.factures);
  const solde = useSelector((state) => state.soldeReducers.soldes);

  const dispatch = useDispatch();

  const tot = factures.reduce(
    (accumulateur, valeurCourante) => accumulateur + valeurCourante.montant,
    0
  );
  const tot2 = solde.reduce(
    (accumulateur, valeurCourante) => accumulateur + valeurCourante.solde,
    0
  );
  useEffect(() => {
    dispatch(getallFacture());
  }, [dispatch]);
  return (
    <div>
      {/* <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
        List Innovices
      </h1> */}
      <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
        {" "}
        Argents Sortant{" "}
      </h1>
      <span className="total"> Total : {tot} DT</span>
      <table className="fact">
        <tr>
          <th>Nom Facture</th>
          <th>Description</th>
          <th>Date</th>
          <th>Montant</th>
          <th>Actions</th>
        </tr>
      </table>
      {factures && factures.map((el) => <Facture facture={el} key={el._id} />)}{" "}
      {/* ********************************************************************************** */}
      <h1 style={{ textAlign: "center", marginTop: "30px" }}>
        Argents Entrant
      </h1>
      <span className="total"> Total : {tot2} DT</span>
      <table className="fact">
        <tr>
          <th>Nom Facture</th>
          <th>Description</th>
          <th>Date</th>
          <th>Montant</th>
          <th>Actions</th>
        </tr>
      </table>
      {solde && solde.map((el) => <Solde sold={el} key={el._id} />)}{" "}
    </div>
  );
};
export default ListFactures;
