import {
  GET_FAMILY_FAILD,
  GET_FAMILY_LOAD,
  GET_FAMILY_SUCCESS,
  GET_ONE_FAMILY,
} from "../constants/Family";

const initialState = {
  families: [],
  family: {},
  isLoad: false,
  isError: false,
};

const familyReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FAMILY_SUCCESS:
      return {
        ...state,
        families: payload.result,
        isLoad: false,
      };
    case GET_ONE_FAMILY:
      return { ...state, family: payload.result, isLoad: false };
    case GET_FAMILY_LOAD:
      return { ...state, isLoad: true };
    case GET_FAMILY_FAILD:
      return {
        ...state,
        isLoad: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default familyReducers;
