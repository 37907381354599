import React, { useEffect } from "react";
import { FaCalendarPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getallSecourisme } from "../JS/actions/secourisme";
import Secourisme from "./Secourisme";

const ListSecourisme = () => {
  const secourismes = useSelector(
    (state) => state.secourismeReducers.secourismes
  );
  const isLoad = useSelector((state) => state.secourismeReducers.isLoad);
  const isError = useSelector((state) => state.secourismeReducers.isError);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallSecourisme());
  }, [dispatch]);
  return (
    <div>
      <>
        {/* <h1> Families list </h1> */}
        {isLoad ? (
          <div className="loading">Loading&#8230</div>
        ) : isError ? (
          <p>Error To get The Secourismes</p>
        ) : (
          <div>
            <h1 style={{ textAlign: "center" }}>
              {" "}
              Stock secourismes{" "}
              <FaCalendarPlus style={{ fontSize: "40px", color: "red" }} />
            </h1>
            <div className="main">
              {secourismes &&
                secourismes.map((el) => (
                  <Secourisme secourism={el} key={el._id} />
                ))}{" "}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ListSecourisme;
