import React from "react";
import "./Contact.css";
const Contact = () => {
  return (
    <>
      <section id="contact">
        <div class="wrapper">
          <h3>CONTACTEZ-NOUS</h3>
          <p>
            La Croissant-Rouge tunisien est une association humanitaire
            tunisienne fondée en 1956, après l'indépendance du pays. Le CRT est
            l'une des sociétés nationales du Mouvement international de la
            Croix-Rouge et du Croissant-Rouge.
          </p>

          <form>
            <label for="name">Telephone</label>
            <input type="text" id="telephone" placeholder="Votre telephone" />
            <label for="email">Email</label>
            <input type="text" id="email" placeholder="Votre Email" />

            <input type="submit" value="Ok" class="button-3" />
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
