import React from "react";

const UserStat = ({ user }) => {
  return (
    <div className=" userCont">
      <div className="table-responsive">
        <table className="table user-list">
          {/* <thead></thead> */}
          <tbody>
            <tr>
              <td> {user.lastName} </td>
              {/* <td> {test.phone} </td> */}
              <td>
                {" "}
                {user.verified ? (
                  <b style={{ color: "green" }}>Verified</b>
                ) : (
                  <b style={{ color: "red" }}>Waiting ...</b>
                )}{" "}
              </td>
              {/* <td> {test.phone} </td> */}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserStat;
