import React, { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { addSolde } from "../JS/actions/Solde";

const AddSolde = () => {
  const [solde, setSolde] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const handleChange = (e) => {
    setSolde({ ...solde, [e.target.name]: e.target.value });
  };
  const handleSave = (e) => {
    e.preventDefault();
    // if (edit) {
    //   dispatch(editEvent(params.id, event, history));
    //   alert("event updated succesfully :)");
    // } else {
    dispatch(addSolde(solde, history));
    alert(" added succesfully :)");
  };
  return (
    <div>
      <Form className="addFamily">
        <h1 style={{ textAlign: "center", color: "black" }}>
          {" "}
          Ajouter le solde{" "}
        </h1>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Name Facture</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Entrez le nom"
              name="factName"
              required
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Facture desription</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Description"
              name="factDescription"
              required
            />
          </Form.Group>
        </Row>
        <Form.Group as={Col}>
          <Form.Label>Montant</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="number"
            placeholder="solde"
            name="solde"
            required
          />
        </Form.Group>

        {/* <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Type event</Form.Label>
          <Form.Select
            onChange={handleChange}
            placeholder="Type event"
            name="eventType"
            type="text"
            defaultValue="Choose..."
            required
          >
            <option>Choisir...</option>
            <option>evennement</option>
            <option>action</option>
          </Form.Select>
        </Form.Group> */}

        <Row className="mb-3"></Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Date Facture</Form.Label>
            <Form.Control
              onChange={handleChange}
              placeholder="date"
              name="factDate"
              type="date"
              required
            />
          </Form.Group>

          {/* <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Montant</Form.Label>
            <Form.Control
              //   onChange={handleChange}
              type="number"
              placeholder="Place"
              name="eventPlace"
              required
            />
          </Form.Group> */}
        </Row>

        <Button
          onClick={handleSave}
          style={{ marginBottom: "15px" }}
          variant="primary"
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default AddSolde;
