import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getallUser } from "../JS/actions/member";
import User from "./User";

const ListUsers = () => {
  const users = useSelector((state) => state.memberReducers.members);

  const isLoad = useSelector((state) => state.memberReducers.isLoad);
  const isError = useSelector((state) => state.memberReducers.error);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallUser());
  }, [dispatch]);
  return (
    <div>
      <h1 style={{ textAlign: "center" }}> Users </h1>
      {isLoad ? (
        <div className="loading">Loading&#8230</div>
      ) : isError ? (
        <p>Error To get The users</p>
      ) : (
        <div className="tabData">
          <div className=" userCont">
            <div className="table-responsive">
              <table
                style={{ background: "#dee222" }}
                className="table user-list "
              >
                <tr>
                  <th>
                    <span className="theadStyle" style={{ color: "black" }}>
                      User
                    </span>
                  </th>
                  {/* <th>
                    <span className="theadStyle" style={{ color: "black" }}>
                      Created
                    </span>
                  </th> */}

                  <th className="text-center">
                    <span className="theadStyle" style={{ color: "black" }}>
                      Phone
                    </span>
                  </th>
                  <th className="text-center">
                    <span className="theadStyle" style={{ color: "black" }}>
                      Status (bann)
                    </span>
                  </th>

                  <th>
                    <span style={{ color: "black" }}>Email</span>
                  </th>
                  <th style={{ color: "black" }}>Action</th>
                </tr>
              </table>
            </div>
          </div>

          {users && users.map((el) => <User test={el} key={el._id} />)}
        </div>
      )}
    </div>
  );
};

export default ListUsers;
