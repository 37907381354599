import axios from "axios";
import {
  GET_NOURRITURE_FAILD,
  GET_NOURRITURE_LOAD,
  GET_NOURRITURE_SUCCESS,
} from "../constants/nourritures";

export const getallNourritures = () => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: GET_NOURRITURE_LOAD });

  try {
    let results = await axios.get("/api/nourriture/nourritures", config);
    // if i send an http request result bech tekho objet { status , headers ,data li jet mel api }
    dispatch({
      type: GET_NOURRITURE_SUCCESS,
      payload: results.data, // (hedhy tji feha un objet fih msg w EVENT)
    });
  } catch (error) {
    dispatch({ type: GET_NOURRITURE_FAILD });
    console.log(error);
  }
};
export const addNourritures = (nourriture, history) => async (dispatch) => {
  const config = {
    headers: {
      authorization: localStorage.getItem("token"),
    },
  };
  dispatch({ type: GET_NOURRITURE_LOAD });
  try {
    await axios.post("/api/nourriture", nourriture, config);
    // dispatch({ type: SUCCESS });
    dispatch(getallNourritures());

    history.push("/nourritures");
  } catch (error) {
    dispatch({ type: GET_NOURRITURE_FAILD });
    console.log(error);
  }
};

export const deleteNourriture = (id) => async (dispatch) => {
  dispatch({ type: GET_NOURRITURE_LOAD });
  try {
    await axios.delete(`/api/nourriture/${id}`);
    dispatch(getallNourritures());
  } catch (error) {
    dispatch({ type: GET_NOURRITURE_FAILD });
    console.log(error);
  }
};
