import {
  GET_OTHER_FAILD,
  GET_OTHER_LOAD,
  GET_OTHER_SUCCESS,
} from "../constants/other";

const initialState = {
  others: [],
  isLoad: false,
  isError: false,
};

const otherReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_OTHER_SUCCESS:
      return {
        ...state,
        others: payload.others,
        isLoad: false,
      };
    case GET_OTHER_LOAD:
      return { ...state, isLoad: true };
    case GET_OTHER_FAILD:
      return {
        ...state,
        isLoad: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default otherReducers;
