import React, { useEffect } from "react";
import { FaBookOpen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getallFourniture } from "../JS/actions/fournitures";
import Fournitures from "./Fournitures";

const ListFourniture = () => {
  const fournitures = useSelector(
    (state) => state.fournitureReducers.fournitures
  );
  const isLoad = useSelector((state) => state.fournitureReducers.isLoad);
  const isError = useSelector((state) => state.fournitureReducers.isError);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallFourniture());
  }, [dispatch]);
  return (
    <div>
      <>
        {/* <h1> Families list </h1> */}
        {isLoad ? (
          <div className="loading">Loading&#8230</div>
        ) : isError ? (
          <p>Error To get The Nourritures</p>
        ) : (
          <div>
            <h1 style={{ textAlign: "center" }}>
              {" "}
              Stock Fournitures{" "}
              <FaBookOpen style={{ fontSize: "30px", color: "#38e1b0" }} />
            </h1>
            <div className="main">
              {fournitures &&
                fournitures.map((el) => (
                  <Fournitures fourniture={el} key={el._id} />
                ))}{" "}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ListFourniture;
