import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { addUser, editUser } from "../JS/actions/member";
import "./AddFamily.css";
const AddUser = () => {
  const [userr, setUserr] = useState({});
  const [edit, setEdit] = useState(false);
  const userToEdit = useSelector((state) => state.memberReducers.Updatemember);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      setEdit(true);
    } else {
      setEdit(false);
    }
    edit
      ? setUserr(userToEdit)
      : setUserr({
          firstName: "",
          lastName: "",
          password: "",
          adresse: "",
          email: "",
          phone: "",
          role: "",
          banned: "",
          verified: "",
        });
  }, [params, edit, userToEdit]);

  const handleChange = (e) => {
    setUserr({ ...userr, [e.target.name]: e.target.value });
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (edit) {
      dispatch(editUser(params.id, userr, history));
      alert("event updated succesfully :)");
    } else {
      dispatch(addUser(userr, history));
      // alert("User added succesfully :)");
    }
  };

  return (
    <Form className="addFamily">
      <h1 style={{ textAlign: "center", color: "black" }}>
        {" "}
        Ajouter un utulisateur{" "}
      </h1>

      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label>Nom</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="text"
            placeholder="Entrez le nom"
            name="firstName"
            required
            value={userr.firstName}
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Prenom</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="text"
            placeholder="Entrez le Prenom"
            name="lastName"
            required
            value={userr.lastName}
          />
        </Form.Group>
        {!edit ? (
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Password</Form.Label>
            <Form.Control
              onChange={handleChange}
              placeholder="password"
              name="password"
              type="password"
              required
              value={userr.password}
            />
          </Form.Group>
        ) : null}
      </Row>

      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Address</Form.Label>
        <Form.Control
          onChange={handleChange}
          placeholder="Entrez l'adresse"
          name="adresse"
          type="text"
          required
          value={userr.adresse}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label>Email</Form.Label>
        <Form.Control
          onChange={handleChange}
          placeholder="Email"
          name="email"
          type="email"
          required
          value={userr.email}
        />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            onChange={handleChange}
            placeholder="Phone"
            name="phone"
            type="number"
            required
            value={userr.phone}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Role</Form.Label>
          <Form.Select
            onChange={handleChange}
            placeholder="Role"
            name="role"
            type="text"
            defaultValue="Choose..."
            required
            value={userr.role}
          >
            <option>Choisir...</option>
            <option>volontaire</option>
            <option>logistique</option>
            <option>trésorier</option>
            <option>admin</option>
            <option> social</option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>banned</Form.Label>
          <Form.Select
            onChange={handleChange}
            placeholder="banned"
            name="banned"
            type="text"
            defaultValue="Choose..."
            required
            value={userr.banned}
          >
            <option>Choisir...</option>
            <option>true</option>
            <option>false</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>verified</Form.Label>
          <Form.Select
            onChange={handleChange}
            placeholder="banned"
            name="verified"
            type="text"
            defaultValue="Choose..."
            required
            value={userr.verified}
          >
            <option>Choisir...</option>
            <option>true</option>
            <option>false</option>
          </Form.Select>
        </Form.Group>
      </Row>

      <Button
        onClick={handleSave}
        style={{ marginBottom: "15px", pointer: "cursor" }}
        variant="primary"
        type="submit"
      >
        Submit
      </Button>
    </Form>
  );
};

export default AddUser;
