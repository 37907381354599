import {
  GET_EVENT_FAILD,
  GET_EVENT_LOAD,
  GET_EVENT_SUCCESS,
  GET_ONE_EVENT,
} from "../constants/Event";

const initialState = {
  events: [],
  event: {},
  isLoad: false,
  isError: false,
};

const eventReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        events: payload.events,
        isLoad: false,
        isError: false,
      };
    case GET_EVENT_LOAD:
      return { ...state, isLoad: true };
    case GET_EVENT_FAILD:
      return {
        ...state,
        isLoad: false,
        isError: true,
      };
    case GET_ONE_EVENT:
      return { ...state, event: payload.result, isLoad: false };
    default:
      return state;
  }
};

export default eventReducers;
