import React, { useEffect, useState } from "react";
import { FaExchangeAlt, FaPen } from "react-icons/fa";
import { MdDelete, MdOutlineRemoveCircle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteUser, getoneUser, memberBanned } from "../JS/actions/member";
import "./User.css";

export const User = ({ test }) => {
  const user = useSelector((state) => state.memberReducers.member);
  const dispatch = useDispatch();
  const [statut, setStatut] = useState({ banned: test.banned });
  const handleDelete = () => {
    const result = window.confirm("are you sure");
    if (result) {
      dispatch(deleteUser(test._id));
    }
  };
  const handleUser = () => {
    dispatch(getoneUser(test._id));
  };
  const handleStatut = () => {
    // setStatut({ banned: !statut });
    // dispatch(memberBanned(statut, test._id));
    if (statut.banned) {
      setStatut({ banned: false });
    } else {
      setStatut({ banned: true });
    }
    dispatch(memberBanned({ banned: !statut.banned }, test._id));
  };

  return (
    <div className=" userCont">
      <div className="table-responsive">
        <table className="table user-list">
          {/* <thead></thead> */}
          <tbody>
            <tr>
              <td>
                <img
                  src="https://bootdey.com/img/Content/avatar/avatar1.png"
                  alt=""
                />
                <a style={{ fontWeight: "bold" }} className="user-link">
                  {test.firstName} {test.lastName}
                </a>
                <span style={{ fontWeight: "bold" }} className="user-subhead">
                  {test.role}
                </span>
              </td>
              <td style={{ fontWeight: "bold", textAlign: "center" }}>
                {" "}
                {test.phone}{" "}
              </td>

              <td className="text-center">
                <span onClick={handleStatut} className="label label-default">
                  {test.banned ? (
                    <p
                      style={{
                        background: "red",
                        color: "white",
                        margin: "20px 30px 30px",
                        borderRadius: "7px",
                        cursor: "pointer",
                      }}
                    >
                      Not Active{" "}
                    </p>
                  ) : (
                    <p
                      style={{
                        background: "green",
                        color: "white",
                        margin: "20px 30px 30px",
                        borderRadius: "7px",
                        cursor: "pointer",
                      }}
                    >
                      Active
                    </p>
                  )}
                </span>
              </td>
              {/* <td> {test.phone} </td> */}
              <td style={{ textAlign: "center", fontWeight: "bold" }}>
                <a>{test.email}</a>
              </td>
              {/* --------------- */}
              <td style={{ width: "20%" }}>
                {user.role == "admin" ? (
                  <a className="table-link">
                    <span className="fa-stack">
                      {/* ******************************************************************************* */}
                      <Link to={`/editUser/${test._id}`}>
                        <FaPen
                          onClick={handleUser}
                          style={{ fontSize: "13px", color: "green" }}
                        />
                      </Link>
                      {/* ******************************************************************************* */}
                    </span>
                  </a>
                ) : (
                  <p style={{ textAlign: "center" }}>
                    No action{" "}
                    {/* <MdOutlineRemoveCircle
                      style={{
                        fontSize: "30px",
                        color: "red",
                      }}
                    />{" "} */}
                  </p>
                )}
                {user.role == "admin" ? (
                  <a className="table-link">
                    <span className="fa-stack">
                      <MdDelete
                        onClick={handleDelete}
                        style={{ fontSize: "13px", color: "red" }}
                      />
                    </span>
                  </a>
                ) : null}
              </td>

              {/* ---------------------- */}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default User;
