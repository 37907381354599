import React, { useEffect } from "react";
import "./ListFamily.css";
import { getallFamily } from "../JS/actions/Family";
import Family from "./Family";
import { useDispatch, useSelector } from "react-redux";

const ListFamily = () => {
  const families = useSelector((state) => state.familyReducers.families);

  const isLoad = useSelector((state) => state.familyReducers.isLoad);
  const isError = useSelector((state) => state.familyReducers.isError);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getallFamily());
  }, [dispatch]);
  return (
    <>
      {/* <h1> Families list </h1> */}
      {isLoad ? (
        <div className="loading">Loading&#8230</div>
      ) : isError ? (
        <p>Error To get The families</p>
      ) : (
        <div className="familiesList">
          <h1> Families list </h1>
          <div className="tbl-header">
            <table cellPadding={0} cellSpacing={0} border={0}>
              <thead>
                <tr>
                  <th>CIN</th>
                  <th>Nom</th>
                  <th>Prenom</th>
                  <th>Adresse</th>
                  <th>Phone</th>
                  <th>Nbr enfants</th>
                  <th>desc enfants</th>
                  <th>Travail</th>
                  <th>Etat maison</th>
                  <th>Etat social</th>
                  <th>Actions</th>
                </tr>
              </thead>
            </table>
          </div>
          {families &&
            families.map((el) => <Family family={el} key={el._id} />)}{" "}
        </div>
      )}
    </>
  );
};

export default ListFamily;
