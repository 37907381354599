import {
  GET_NOURRITURE_FAILD,
  GET_NOURRITURE_LOAD,
  GET_NOURRITURE_SUCCESS,
} from "../constants/nourritures";

const initialState = {
  nourritures: [],
  isLoad: false,
  isError: false,
};

const nourritureReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_NOURRITURE_SUCCESS:
      return {
        ...state,
        nourritures: payload.nourritures,
        isLoad: false,
      };
    case GET_NOURRITURE_LOAD:
      return { ...state, isLoad: true };
    case GET_NOURRITURE_FAILD:
      return {
        ...state,
        isLoad: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default nourritureReducers;
