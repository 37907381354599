import React, { useEffect } from "react";
import "./MainDash.css";
import hello from "../assets/img/hello.svg";
import white_logo from "../assets/img/white_logo.png";
import best_logo from "../assets/img/best_logo.png";

import Chart from "./Charts";
import { useDispatch, useSelector } from "react-redux";
import {
  FaHandsHelping,
  FaRocket,
  FaSmileBeam,
  FaSnowman,
  FaUserAlt,
  FaUserAltSlash,
} from "react-icons/fa";
import { FaHouseUser } from "react-icons/fa";
import { getallEvent } from "../JS/actions/Event";
import { getallFamily } from "../JS/actions/Family";
import { getallUser } from "../JS/actions/member";
import { getallFacture } from "../JS/actions/Facture";
import { getallSolde } from "../JS/actions/Solde";

const MainDash = () => {
  const user = useSelector((state) => state.memberReducers.member);
  const users = useSelector((state) => state.memberReducers.members);
  const isLoad = useSelector((state) => state.memberReducers.isLoad);
  const isError = useSelector((state) => state.memberReducers.error);
  const events = useSelector((state) => state.eventReducers.events);
  const families = useSelector((state) => state.familyReducers.families);
  const facts = useSelector((state) => state.factureReducers.factures);
  const solde = useSelector((state) => state.soldeReducers.soldes);

  // const factures = useSelector((state) => state.familyReducers.factures);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallEvent());
    dispatch(getallFamily());
    dispatch(getallUser());
    dispatch(getallFacture());
    dispatch(getallSolde());

    // dispatch(getallFacture());
  }, [dispatch]);
  return (
    <main>
      {isLoad ? (
        <div className="loading">Loading&#8230</div>
      ) : isError ? (
        <h1>error to get the data dashboard </h1>
      ) : (
        <div className="main__container">
          {/* <!-- MAIN TITLE STARTS HERE --> */}

          <div className="main__title">
            <img src={hello} alt="hello" />
            <div className="main__greeting">
              <h1>
                Hello {user && user.firstName} {user && user.lastName}{" "}
              </h1>
              <p>
                {" "}
                Welcome to your dashboard {user && user.firstName} , Manage your
                Data as you like
              </p>
              <p> Role : {user && user.role} </p>
            </div>
          </div>

          {/* <!-- MAIN TITLE ENDS HERE --> */}

          {/* <!-- MAIN CARDS STARTS HERE --> */}
          <div className="main__cards">
            <div className="card">
              <div className="card_inner">
                <p className="text-primary-p">Nombre des membres</p>
                <span className="font-bold text-title">{users.length}</span>
                <FaUserAlt style={{ fontSize: "20px", color: "#35a4ba" }} />
              </div>
            </div>

            <div className="card">
              {/* <i className="fa fa-calendar fa-2x text-red" aria-hidden="true"></i> */}

              <div className="card_inner">
                <p className="text-primary-p">Nombre des familles</p>
                <span className="font-bold text-title">{families.length}</span>
                <FaHouseUser style={{ fontSize: "30px", color: "#38e1b0" }} />
              </div>
            </div>

            <div className="card">
              {/* <i
                className="fa fa-thumbs-up fa-2x text-green"
                aria-hidden="true"
              ></i> */}
              <div className="card_inner">
                <p className="text-primary-p">Nombres des factures </p>
                <span className="font-bold text-title">
                  {facts.length + solde.length}
                </span>
                <FaSnowman style={{ fontSize: "29px", color: "#f65a6f" }} />
              </div>
            </div>

            <div className="card">
              <div className="card_inner">
                <p className="text-primary-p">Nombre des Events (2022)</p>
                <span className="font-bold text-title">{events.length}</span>
                <FaRocket style={{ fontSize: "25px" }} />
              </div>
            </div>
          </div>
          <h1 className="crtt">
            Be Positive...{" "}
            <FaSmileBeam style={{ fontSize: "40px", color: "yellow" }} />
          </h1>
          {/* <!-- MAIN CARDS ENDS HERE --> */}

          {/* <!-- CHARTS STARTS HERE --> */}
          <div className="charts">
            <div className="charts__left">
              <div className="charts__left__title">
                <div>
                  <h1>
                    Statut membres{" "}
                    <FaUserAltSlash
                      style={{
                        fontSize: "15px",
                        marginRight: "6px",
                        color: "#35a4ba",
                      }}
                    />{" "}
                  </h1>
                  {/* <p>Cupertino, California, USA</p> */}
                </div>
                <i className="fa fa-usd" aria-hidden="true"></i>
              </div>
              <Chart />
            </div>
            <div className="charts__right">
              <div className="charts__right__title">
                <div>
                  <h1>General view</h1>
                  <p>Membres,Familles, Events,Factures</p>
                </div>
                <i className="fa fa-usd" aria-hidden="true"></i>
              </div>
              <div className="charts__right__cards">
                <div className="card1">
                  <FaUserAlt style={{ fontSize: "25px" }} />

                  <h1>Membres</h1>
                  <p>{users.length}</p>
                </div>

                <div className="card2">
                  <FaHouseUser style={{ fontSize: "25px" }} />
                  <h1>Familles</h1>
                  <p>{families.length}</p>
                </div>

                <div className="card3">
                  <FaRocket style={{ fontSize: "25px" }} />
                  <h1>Events</h1>
                  <p>{events.length}</p>
                </div>

                <div className="card4">
                  <FaSnowman style={{ fontSize: "25px" }} />
                  <h1>Factures</h1>
                  <p>{facts.length + solde.length}</p>
                </div>
              </div>
              <img
                style={{ width: "500px", margin: "55px -22px 16px -22px" }}
                src={best_logo}
                alt="logo"
              />
              {/* <img
                style={{ margin: "55px 96px 15px 96px" }}
                src={white_logo}
                alt="logo"
              /> */}
              <h1 className="crtt">
                Humanity...
                <FaHandsHelping
                  style={{ color: "orange", fontSize: "40px" }}
                />{" "}
                <FaSmileBeam style={{ fontSize: "40px" }} />
              </h1>
            </div>
          </div>
          {/* <!-- CHARTS ENDS HERE --> */}
        </div>
      )}
    </main>
  );
};

export default MainDash;
