import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFamily, getOneFamily } from "../JS/actions/Family";
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import "./Family.css";
import { Link } from "react-router-dom";

const Family = ({ family }) => {
  const user = useSelector((state) => state.memberReducers.member);

  const dispatch = useDispatch();
  const handleDelete = () => {
    const result = window.confirm("are you sure");
    if (result) {
      dispatch(deleteFamily(family._id));
    }
  };
  const handleFamily = () => {
    dispatch(getOneFamily(family._id));
  };
  return (
    <div>
      <div className="tbl-content">
        <table cellPadding={0} cellSpacing={0} border={0}>
          <tbody>
            <tr>
              <td>{family.numCin}</td>

              <td>{family.firstName}</td>
              <td>{family.lastName}</td>
              <td>{family.adresse}</td>
              <td>{family.phone} </td>
              <td>{family.nbEnfant} </td>
              <td>{family.descEnfant}</td>
              <td> {family.job} </td>
              <td>{family.etatMaison}</td>
              <td>{family.etatSocial}</td>
              <td>
                {user.role == "admin" ? (
                  <>
                    <MdDelete
                      onClick={handleDelete}
                      style={{ color: "red", fontSize: "18px" }}
                    />{" "}
                    <Link to={`/editFamily/${family._id}`}>
                      <FaPen
                        onClick={handleFamily}
                        style={{ color: "green", fontSize: "18px" }}
                      />
                    </Link>
                  </>
                ) : user.role == "social" ? (
                  <>
                    <MdDelete
                      onClick={handleDelete}
                      style={{ color: "red", fontSize: "18px" }}
                    />{" "}
                    <Link to={`/editFamily/${family._id}`}>
                      <FaPen
                        onClick={handleFamily}
                        style={{ color: "green", fontSize: "18px" }}
                      />
                    </Link>
                  </>
                ) : user.role == "logistique" ? (
                  <>
                    <MdDelete
                      onClick={handleDelete}
                      style={{ color: "red", fontSize: "18px" }}
                    />{" "}
                    <Link to={`/editFamily/${family._id}`}>
                      <FaPen
                        onClick={handleFamily}
                        style={{ color: "green", fontSize: "18px" }}
                      />
                    </Link>
                  </>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* </section> */}
    </div>
  );
};

export default Family;
