import React, { useEffect } from "react";
import { MdFastfood } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getallNourritures } from "../JS/actions/nourritures";
// import { MdFastfood, MdOutlineAddCircle } from "react-icons/md";
import Nourriture from "./Nourriture";
import "./Nourritures.css";

const ListNourritures = () => {
  const nourritures = useSelector(
    (state) => state.nourritureReducers.nourritures
  );
  const isLoad = useSelector((state) => state.nourritureReducers.isLoad);
  const isError = useSelector((state) => state.nourritureReducers.isError);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallNourritures());
  }, [dispatch]);
  return (
    <div>
      <>
        {/* <h1> Families list </h1> */}
        {isLoad ? (
          <div className="loading">Loading&#8230</div>
        ) : isError ? (
          <p>Error To get The Nourritures</p>
        ) : (
          <div>
            <h1 style={{ textAlign: "center" }}>
              {" "}
              Stock Nourritures
              <MdFastfood style={{ fontSize: "30px", color: "orange" }} />{" "}
            </h1>
            <div className="main">
              {nourritures &&
                nourritures.map((el) => (
                  <Nourriture nourriture={el} key={el._id} />
                ))}{" "}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ListNourritures;
